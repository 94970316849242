import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './App.css';
import Tabs from "./components/Tabs";
import Button from 'react-bootstrap/Button';


function App() {
  const nombreTransmision = "INNOVACIÓN TECNOLÓGICA EN CIRUGÍA DE PARED ABDOMINAL";
  const fechaTransmision = "23 de enero, 19:00 hrs.";

  const video = "https://vimeo.com/event/4868385/embed"; //https://player.vimeo.com/video/2386692?h=6718c06abe&title=0&byline=0&portrait=0";
  const chat =  "https://vimeo.com/event/4868385/chat/";
  const ver = false;

  /*
              <div label="Chat">
              <div className='container'>
                <iframe src={chat} width="100%" height="100%" frameborder="0"></iframe>
              </div>
            </div>

            */
  return (
    <Container fluid>
      <Row >
        <Col>
          <div className="header">
            <div className="headertitulo">
              <div >
                <img
                  className='logoh'
                  src={require('./logos-transmisiones.png')}
                  alt='logo amcg' />
              </div>

              <div className="headertexto">
                <p className="nombretransmision">{nombreTransmision}</p>
                <p className="fechatransmision">{fechaTransmision}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='videoseccion'>
        <Col sm >
          <img src={require('./imagen para video.png')} className="ImageVideo ocultar" />
          <iframe src={video} width="100%" height="450" frameborder="0" allow="autoplay; fullscreen; picture-in-picture " allowfullscreen className=''></iframe>
          <div className='col-centered channel'>
            <a href="https://amcg.mx/" target="_blank" > <Button variant="primary">Canal 1</Button>{' '}</a>
            <a href="https://amcg2.mx/" target="_blank" > <Button variant="primary">Canal 2</Button>{' '}</a>
          </div>
          <p className='link'>Si no visualiza la transmisión a la hora señalada de la Sesión Académica, actualice la página o de <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => window.location.reload()}>clic aquí</span></p>

        </Col>
        <Col sm >
          <Tabs >
          <div label="Chat">
              <div className='container'>
                <iframe src={chat} width="100%" height="100%" frameborder="0"></iframe>
              </div>
            </div>
            <div label="Programa">
              <div className=''>
                <p className='titulotabs'>Programa</p>
                <br />
                <ul className='listatabs'>
                  <li className='lista'>
                    <p className='horatabs'>19:00 – 19:05 hrs.</p>
                    <p className='titulopresentacion'>​Introducción y bienvenida.</p>
                    <p className='nombredoctores'>Dra. Adriana Santos Manzur.</p>
                    <p className='nombredoctores'>Dra. Karina Sánchez Reyes.</p>
                    <p className='nombredoctores'>Coordinadoras de las Sesiones Académicas 2025.</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:05 – 19:20 hrs.</p>
                    <p className='titulopresentacion'>Hernia: El chatbot.</p>
                    <p className='nombredoctores'>Dr. Julio Mayol</p>
                    <p className='nombredoctores'>Universidad Complutense Madrid, España</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:20 – 19:35 hrs.</p>
                    <p className='titulopresentacion'>Innovación en el tratamiento de la hernia compleja.</p>
                    <p className='nombredoctores'>​Dra. Lorena Huerta Orozco</p>
                    <p className='nombredoctores'>​HGZ 89 IMSS, Guadalajara, jal</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:35 – 19:50 hrs.</p>
                    <p className='titulopresentacion'>RTL, small bites o malla, ¿cuál es la mejor opción en la prevención de hernia incisional?</p>
                    <p className='nombredoctores'>Dr. Edgard Efren Lozada Hernandez</p>
                    <p className='nombredoctores'>2do. Vicepresidente AMH, León,Gto.</p>
                  </li>
                  <li className='lista'>
                    <p className='horatabs'>19:50 – 20:00 hrs.</p>
                    <p className='titulopresentacion'>Preguntas y respuestas.</p>
                  </li>
                </ul>
              </div> 
            </div>
            <div label="¿Qué es la AMCG?">
              <img className='logoh2'
                src={require('./logo.png')}
                alt='logo amcg' />
              <p className='titulotabs'>¿Qué es la AMCG?</p>
              <p className='textotabs'>La Asociación Mexicana de Cirugía General, A. C.,
                es una organización integrada por cirujanos generales con título
                reconocido por las autoridades competentes. Dentro de sus finalidades,
                proclama la necesidad de pugnar por una mejor preparación científica,
                técnica, cultural, moral, legal y social de todos sus Asociados para
                optimizar las capacidades del profesionista, a fin de ejercer sus
                labores dentro de un marco ético, legal y del más alto nivel técnico
                que demanda esta especialidad. Como grupo social que realiza sus
                actividades para lograr la salud y bienestar del ser humano y la sociedad,
                se esforzará porque sus miembros actúen con la responsabilidad que nuestra
                especialidad exige.
                <br />
                <a href='https://amcg.org.mx/' target="_blank">Más información →</a>
              </p>
            </div>
          </Tabs>

        </Col>
      </Row>
      <Row className='constancia'>
        <Col>
          <p className='titulocons'>Solicite su constancia</p>
          <p className='textocons'>El formulario de registro de constacias se habilita el mismo día de la sesión Académica</p>
          <div className='col-centered'>
            <a href='https://constancias.amcg.mx/registros/' target="_blank" > <Button variant="warning">Ir a registro</Button>{' '}</a>

          </div>


        </Col>
      </Row>
    </Container>

  );
}

export default App;
